<template>
  <div class="aboutus">
    <!--  -->
    <div class="one-bg-box">
      <div class="obb-box">
        <div class="obb-tit">山东路通石油</div>
        <div class="obb-txt">卓越成就非凡</div>
        <div class="obb-txt">品质源于信念</div>
        <div class="obb-bottom-box">
          <div class="obb-bottom-b-icon obi-l"></div>
          <div class="obb-bottom-b-middle">用户满意是我们永恒的追求</div>
          <div class="obb-bottom-b-icon obi-r"></div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="part-building-box">
      <div class="pbb-tit-en">Distribution of service stations</div>
      <div class="pbb-tit">
        <div class="pbbt-line"></div>
        <div class="pbbt-tit">油站分布</div>
      </div>
      <div class="pbb-content">
        <img src="../static/salfimg.png" alt="" class="pbbc-img">
      </div>
    </div>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageBottom from "../components/PageBottom.vue";
export default {
  components: {
    PageBottom,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.aboutus {
  .one-bg-box {
    height: 400px;
    background-image: url("../static/salesbg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .obb-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .obb-tit {
        margin-top: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #e40316;
        opacity: 1;
      }
      .obb-txt {
        margin-top: 10px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
      }
      .obb-bottom-box {
        display: flex;
        .obb-bottom-b-middle {
          padding: 0 5px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          height: 30px;
          background-color: #ffffff;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .obb-bottom-b-icon {
          width: 60px;
          height: 30px;
          background-size: 100% 100%;
        }
        .obi-l {
          background-image: url("../static/homeiconleft.png");
        }
        .obi-r {
          background-image: url("../static/homeiconright.png");
        }
      }
    }
  }

  .part-building-box {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pbb-tit-en {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2e86a9;
      opacity: 1;
    }
    .pbb-tit {
      width: 600px;
      .pbbt-line {
        margin-top: 50px;
        width: 100%;
        border-bottom: 1px solid #000000;
      }
      .pbbt-tit {
        height: 40px;
        text-align: center;
        top: -20px;
        left: 200px;
        position: relative;
        width: 200px;
        background-color: #fff;
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2e86a9;
        opacity: 1;
      }
    }
    .pbb-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .pbbc-img{
        width: 800px;
        height: 500px;
      }
    }
  }
}
</style>

